import React from "react"
import { Link as NavLink } from "gatsby"
import { useLocation } from '@reach/router';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CancelIcon from '@mui/icons-material/Cancel';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import logo from "../images/logo.png";

export default function Header(props) {
    const { window, title } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    }

    const container = window !== undefined ? () => window().document.body : undefined;

    const navLinks = (
        <div>
            <List sx={{ display: {xs: 'block', md: 'flex'} }}>
                {[['About', '/about'], ['Deals', '/deals'], ['Contact', '/contact']].map((item, index) => (
                    <ListItem button color="primary" key={item[0]} component={NavLink} to={item[1]} selected={item[1] === location.pathname}>
                        <ListItemText primary={item[0]} sx={{ textAlign: { xs: 'center', md: 'left' } }} />
                    </ListItem>
                ))}
                <ListItem sx={{justifyContent: 'center'}}>
                    <Button variant="contained" color="secondary">Follow</Button>
                    {/* <a href="https://twitter.com/dealnerds?ref_src=twsrc%5Etfw" className="twitter-follow-button" data-size="large" data-show-count="false">Follow @dealnerds</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script> */}
                </ListItem>
            </List>
        </div>
    );

    return (
        <>
            <AppBar color="default" style={{ backgroundColor: "#fff" }} elevation={trigger ? 4 : 0}>
                <Toolbar>
                    <Link
                        variant="h6"
                        underline="none"
                        color="inherit"
                        href="/"
                        sx={{ fontSize: 24, display: 'flex', flex: '1 auto', alignItems: 'center', marginRight: '40px', whiteSpace: 'nowrap' }}
                    >
                        <img style={{ height: '50px' }} src={logo} />
                        {title}
                    </Link>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', a: { marginRight: '25px', fontSize: '1.1rem' } }}>
                        {navLinks}
                    </Box>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleDrawerToggle}
                        sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                container={container}
                sx={{
                    display: { md: 'none', xs: 'block' },
                    width: '90%',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: '100%',
                        boxSizing: 'border-box',
                    },
                    "& .MuiDrawer-paper": {
                        width: '90%'
                      }
                }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                anchor="left">
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={handleDrawerToggle}>
                        <CancelIcon />
                    </IconButton>
                </Box>
                {navLinks}
            </Drawer>
        </>
    )
}