import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Header from "../components/header";

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <>
      <div className="site-wrapper">
        <Header title={data.site.siteMetadata.title}/>
        <Box sx={{pt: '64px'}}>
        {children}
        </Box>
      </div>
      <Divider />
      <Box component="footer" sx={{pl: 2, pr: 2, pt: 5, pb: 5, textAlign: 'center' }}>
        <p>Great deals on quality products.

          If you buy something through a post, Deal Nerds may receive a commission from one of our affiliate partners.</p>
        <p>&copy; {new Date().getFullYear()} Deal Nerds</p>
      </Box>
    </>
  )
}